import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import PicsGrid from "../components/PicsGrid/PicsGrid";
import Description from "../components/Description/Description";

export const ecommerceImage = graphql`
  fragment ecommerceImage on File {
    childImageSharp {
      fluid(maxHeight: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "ecommerce/hero.jpg" }) {
      ...ecommerceImage
    }
    image1: file(relativePath: { eq: "ecommerce/THE-ATTICO_1449.jpg" }) {
      ...ecommerceImage
    }
    image2: file(relativePath: { eq: "ecommerce/THE-ATTICO_1353.jpg" }) {
      ...ecommerceImage
    }
    image3: file(relativePath: { eq: "ecommerce/THE-ATTICO_1235.jpg" }) {
      ...ecommerceImage
    }
    image4: file(relativePath: { eq: "ecommerce/THE-ATTICO_2843.jpg" }) {
      ...ecommerceImage
    }
    image5: file(relativePath: { eq: "ecommerce/THE-ATTICO_2920.jpg" }) {
      ...ecommerceImage
    }
    image6: file(relativePath: { eq: "ecommerce/THE-ATTICO_2989.jpg" }) {
      ...ecommerceImage
    }
    image7: file(relativePath: { eq: "ecommerce/W0BD0BWCUD0-P68G.jpg" }) {
      ...ecommerceImage
    }
    image8: file(relativePath: { eq: "ecommerce/W0YK40D42J1-HSTA.jpg" }) {
      ...ecommerceImage
    }
    image9: file(relativePath: { eq: "ecommerce/0BG7955593Z-F86H2.jpg" }) {
      ...ecommerceImage
    }
    image10: file(relativePath: { eq: "ecommerce/360-1.jpg" }) {
      ...ecommerceImage
    }
    image11: file(relativePath: { eq: "ecommerce/360-2.jpg" }) {
      ...ecommerceImage
    }
    image12: file(relativePath: { eq: "ecommerce/360-3.jpg" }) {
      ...ecommerceImage
    }
  }
`;

const WearingArticle = () => (
  <>
    <span>
      Raccontare storie attraverso le immagini è ciò che facciamo. Anche quando
      si tratta di scatti professionali pensati appositamente per il mondo
      dell’e-commerce. Parallel Milano offre un team di professionisti del
      settore composto da fotografi, stylist, make-up artist, hair stylist e
      post produttori capaci di rispondere alle esigenze di velocità, coerenza
      stilistica, precisione e qualità che il mondo dell’online di oggi
      richiede.
    </span>
    <h4>Indossato</h4>
    <p>
      Capi e accessori prendono vita sul corpo di modelli e modelle,
      valorizzando tutte le caratteristiche del prodotto. Gli scatti, ricercati
      e contemporanei vengono gestiti per garantire la massima uniformità
      all’interno del sito.
    </p>
  </>
);

const StillLifeArticle = () => (
  <>
    <h4>Still Life</h4>
    <p>
      L’anima del prodotto raccontata in uno scatto. I nostri Still Life mettono
      in evidenza il pregio dei materiali e l’eleganza del prodotto. Realizzati
      su sfondi neutri o personalizzati, garantiscono tutta la qualità e la
      velocità di una produzione digitale realizzata interamente in Italia.
    </p>
  </>
);

const GhostArticle = () => (
  <>
    <h4>Ghost</h4>
    <p>
      La qualità delle nostre fotografie Ghost, grandi protagoniste dei portali
      di e-commerce, è confermata da un’estrema cura dei dettagli. Il tutto per
      valorizzare al meglio il prodotto con l’aiuto della tridimensionalità.
    </p>
  </>
);

const Still360Article = () => (
  <>
    <h4>Still life 360</h4>
    <p>
      Immagini, certo, ma anche video e speciali tool interattivi. Quelli che
      proponiamo sono Still Life che ruotano a 360°, realizzati con sofisticate
      attrezzature di ultima generazione, in grado di mostrare il prodotto nella
      sua interezza e in ogni minimo particolare.
    </p>
  </>
);

const Ecommerce = ({ data }) => (
  <Layout pageUrl="ecommerce">
    <SEO title="E-commerce" />
    <Hero
      home={false}
      pageTitle="E-commerce"
      heroImage={data.heroImage.childImageSharp.fluid}
      heroAlt="E-commerce hero"
    />
    <section id="wearing">
      <PicsGrid
        pic1={data.image1.childImageSharp.fluid}
        alt1="Wearings showcase"
        pic2={data.image2.childImageSharp.fluid}
        alt2="Wearings showcase"
        pic3={data.image3.childImageSharp.fluid}
        alt3="Wearings showcase"
      />
      <Description
        heading="Un approccio contemporaneo al mondo dell'online"
        descriptionArticle={<WearingArticle />}
      />
    </section>
    <section id="still=life">
      <PicsGrid
        pic1={data.image4.childImageSharp.fluid}
        alt1="Still life showcase"
        pic2={data.image5.childImageSharp.fluid}
        alt2="Still life showcase"
        pic3={data.image6.childImageSharp.fluid}
        alt3="Still life showcase"
      />
      <Description
        heading="L'essenza del prodotto raccontata in uno scatto"
        descriptionArticle={<StillLifeArticle />}
      />
    </section>
    <section id="ghost">
      <PicsGrid
        hasGap
        pic1={data.image7.childImageSharp.fluid}
        alt1="Ghost showcase"
        pic2={data.image8.childImageSharp.fluid}
        alt2="Ghost showcase"
        pic3={data.image9.childImageSharp.fluid}
        alt3="Ghost showcase"
      />
      <Description
        heading="Volume, vestibilità e fitting in una sola immagine"
        descriptionArticle={<GhostArticle />}
      />
    </section>
    <section id="still360">
      <PicsGrid
        hasGap
        pic1={data.image10.childImageSharp.fluid}
        alt1="Still 360 showcase"
        pic2={data.image11.childImageSharp.fluid}
        alt2="Still 360 showcase"
        pic3={data.image12.childImageSharp.fluid}
        alt3="Still 360 showcase"
      />
      <Description
        heading="L'immagine che gira, i dettagli che emergono"
        descriptionArticle={<Still360Article />}
      />
    </section>
  </Layout>
);

export default Ecommerce;
